import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
/**
 * @method getLevelIcon
 * @method postUploadLevelIcon
 * @method postUploadExcel
 * @method postEndCampaign
 * @method postCreateLevel
 * @method getLevelList
 * @method getHistory
 * @method postUpdateLevel
 * @method postPublishgroup
 * @method postCancelPublishgroup
 */
export default {
  KEY_COOKIE_USER: 'CRMUser',
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get(this.KEY_COOKIE_USER).Token
  },
  agency_id: function () {
    return Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      ? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      : this.config().agency_id
  },
  getLevelIcon: function () {
    const params = {
      agencyId: this.agency_id(),
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/getLevelIcon'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  postUploadLevelIcon: function (params) {
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/uploadLevelIcon'
    params.agencyId = this.agency_id()

    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postEndCampaign: function () {
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/delgroup'
    let params = {
      agencyId: this.agency_id(),
    }

    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCreateLevel: function (data) {
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/creategroup'
    let params = {
      agencyId: this.agency_id(),
      model: JSON.stringify(data),
    }

    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getLevelList: function () {
    const params = {
      agencyId: this.agency_id(),
      device_app_id: this.config().client_id,
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/office_list'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getHistory: function (startyear, endyear) {
    const params = {
      agencyId: this.agency_id(),
      device_app_id: this.config().client_id,
      startyear: startyear ? startyear : 0,
      endyear: endyear ? endyear : 0,
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/history'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  postUpdateLevel: function (data, strGroupkey) {
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/updategroup'
    let params = {
      agencyId: this.agency_id(),
      model: JSON.stringify(data),
      groupkey: strGroupkey,
    }

    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postPublishgroup: function (strGroupkey) {
    var strUrl = this.config().bzbsModuleUrl + '/crmpluslevel/publishgroup'
    let params = {
      agencyId: this.agency_id(),
      groupkey: strGroupkey,
    }
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCancelPublishgroup: function (strGroupkey) {
    var strUrl =
      this.config().bzbsModuleUrl + '/crmpluslevel/cancel_publishgroup'
    let params = {
      agencyId: this.agency_id(),
      groupkey: strGroupkey,
    }
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postUploadExcel: function (data, file) {
    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/profile'
    // var form = new FormData()
    // form.append('branchfile', file[0])

    let params = {
      agencyId: this.agency_id(),
      device_app_id: this.config().client_id,
      business_name: data.business_name,
      business_type: data.business_type,
      email: data.email,
      district_code: data.district_code,
      district_name: data.district_name,
      subdistrict_code: data.subdistrict_code,
      subdistrict_name: data.subdistrict_name,
      province_code: data.province_code,
      province_name: data.province_name,
      zipcode: data.zipcode,
      branch: data.branch,
      logo: data.logo,
      branchfile: file[0],
    }

    // console.log(file, file[0])

    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
