<template>
  <div class="container-fluid">
    <Header />

    <!-- Menu Filter -->
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header pt-2 pb-2">
          <div class="col-12">
            <div class="row">
              <div
                class="
                  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12
                  title-header
                  pl-4
                "
              >
                <div @click="goto('CreateUserLevel')">
                  <img
                    class="button-arrow-back mr-3"
                    src="@/assets/images/userlevel/arrow_back.svg"
                    alt=""
                  />
                </div>
                {{ lbl['user-level-inactive-member-level'] }}
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pl-4">
                <b-form-group label-for="tags-component-select">
                  <b-form-tags
                    id="tags-component-select"
                    v-model="value"
                    size="lg"
                    add-on-change
                    no-outer-focus
                    @input="onOptionClick"
                  >
                    <template
                      v-slot="{
                        tags,
                        inputAttrs,
                        inputHandlers,
                        disabled,
                        removeTag,
                      }"
                    >
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-0"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            @remove="removeTag(tag)"
                            :title="tag"
                            :disabled="disabled"
                            variant="info"
                            >{{ tag }}</b-form-tag
                          >
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        :disabled="disabled || tags.length === 2"
                        :options="availableOptions"
                      >
                        <template #first>
                          <option disabled value="">
                            {{ lbl['fillter-by-tag'] }}
                          </option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isNoData" class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="col-12">
          <a-empty class="py-5" />
        </div>
      </div>
    </div>

    <div v-else>
      <div v-for="(item, index) in historyList" :key="index">
        <div class="mx-auto mt-1 content-outer-container">
          <div class="wrap-UserLevel">
            <div class="mx-auto content-inner-container border-header">
              <div class="row">
                <div
                  class="
                    col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12
                    title-header
                    pl-4
                  "
                >
                  {{ dateShotFormat(item.Value) }}
                  <span class="badge-end ml-3">
                    <img src="@/assets/images/userlevel/Stop.svg" alt="" />
                    {{ lbl['user-level-status-end'] }}
                  </span>
                </div>

                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                  <a-tooltip placement="bottom">
                    <template #title>
                      <span>{{ lbl['user-level-note-member-level'] }}</span>
                    </template>
                    <a-button
                      v-if="item.Value[0].IsUndo == true"
                      variant="outline-primary"
                      class="btn-outline-primary float-right"
                      @click="handleRedo()"
                    >
                      <img
                        src="@/assets/images/userlevel/Repeat.svg"
                        alt=""
                        class="icon-edit mr-1"
                      />
                      {{ lbl['user-level-redo-this-user-level'] }}
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>

            <div class="user-body">
              <div class="body-container">
                <div class="body-main">
                  <div class="row">
                    <div
                      class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-4"
                    >
                      <!-- box Member level by -->
                      <div class="box-container">
                        <div class="row">
                          <div class="col-12 title">
                            {{ lbl['user-level-member-level-by'] }}
                          </div>
                        </div>

                        <div class="row mt-4">
                          <div class="col-12">
                            <div class="main-content view">
                              <ul
                                class="selection-container"
                                v-for="item_mode in selectionChoice"
                                :key="item_mode.id"
                              >
                                <li
                                  class="selection-item"
                                  :class="{
                                    selected:
                                      item_mode.id ===
                                      dataMode(item.Value[0].Mode),
                                  }"
                                >
                                  <div class="selection-icon">
                                    <img
                                      :src="
                                        require(`../../assets/images/userlevel/${item_mode.img}`)
                                      "
                                      alt=""
                                    />
                                  </div>
                                  <div class="selection-header">
                                    {{ item_mode.name }}
                                  </div>
                                  <div class="selection-desc">
                                    {{ item_mode.desc }}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="set-display-conatainer">
                              <div class="title">
                                <strong>
                                  {{ lbl['user-level-display-setting-title'] }}
                                </strong>
                                <a-tooltip placement="bottom">
                                  <template slot="title">
                                    <span>
                                      {{
                                        lbl[
                                          'user-level-display-setting-tooltip'
                                        ]
                                      }}
                                    </span>
                                  </template>
                                  <a-icon
                                    type="info-circle"
                                    class="info-icon"
                                  />
                                </a-tooltip>
                              </div>
                              <a-switch
                                v-if="
                                  isDisplayUserLevel(
                                    !item.Value[0].HideLevelOnConsumerView,
                                  )
                                "
                                :checked-children="
                                  lbl[
                                    'user-level-display-setting-title-switch-btn'
                                  ]
                                "
                                :un-checked-children="
                                  lbl[
                                    'user-level-display-setting-title-switch-btn'
                                  ]
                                "
                                default-checked
                                disabled
                              />
                              <a-switch
                                v-else
                                :checked-children="
                                  lbl[
                                    'user-level-display-setting-title-switch-btn'
                                  ]
                                "
                                :un-checked-children="
                                  lbl[
                                    'user-level-display-setting-title-switch-btn'
                                  ]
                                "
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- box Condition -->
                      <div class="box-container">
                        <div class="row">
                          <div class="col-12 title">
                            {{ lbl['user-level-condition'] }}
                          </div>
                        </div>

                        <div class="row mt-4">
                          <div class="col-12">
                            <div>
                              <span class="condition-title"
                                >{{ lbl['user-level-member-level-start'] }} :
                              </span>
                              {{ dateFormat(item.Value[0].StartDate) }}
                            </div>
                            <div class="mt-4">
                              <span class="condition-title"
                                >{{ lbl['edit-date'] }} :
                              </span>
                              {{ dateFormat(item.Value[0].UpdateDate) }}
                            </div>
                            <div class="mt-4">
                              <span class="condition-title"
                                >{{ lbl['user-level-member-level-setting'] }} :
                              </span>
                              <span class="condition-value">{{
                                item.Value[0].LevelType
                              }}</span>
                              <span v-if="item.Value[0].LevelType == 'advance'">
                                {{ lbl['user-level-member-tier-detail'] }}
                              </span>
                              <span v-else>
                                {{ lbl['user-level-reset-period'] }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-4"
                    >
                      <!-- box Member Level -->
                      <div class="box-container">
                        <div class="row">
                          <div class="col-12 title">
                            {{ lbl['usr-level-manage-tab'] }}
                          </div>
                        </div>

                        <div class="row mt-4">
                          <div class="col-12">
                            <a-list
                              bordered
                              v-for="(item_badge, index_badge) in item.Value"
                              :key="index_badge"
                            >
                              <template>
                                <div class="d-flex w-100">
                                  <div class="border-badge">
                                    <img
                                      v-if="item_badge.UserLevelIconUrl"
                                      class="badge-image"
                                      :src="`${item_badge.UserLevelIconUrl}`"
                                      alt=""
                                    />
                                    <span class="badge-name">{{
                                      item_badge.UserLevelName
                                    }}</span>
                                  </div>
                                </div>
                                <div
                                  v-if="index_badge != item.Value.length - 1"
                                  class="mt-3 int-point"
                                >
                                  {{ stringFormat(item_badge.Value) }} -
                                  {{ stringFormat(item_badge.MaxValue) }}
                                  {{ lbl['user-level-number-point'] }}
                                </div>
                                <div
                                  v-else-if="
                                    index_badge == item.Value.length - 1 &&
                                    item_badge.MaxValue != null &&
                                    item_badge.MaxValue != '' &&
                                    item_badge.MaxValue != 0
                                  "
                                  class="mt-3 int-point"
                                >
                                  {{ stringFormat(item_badge.Value) }} -
                                  {{ stringFormat(item_badge.MaxValue) }}
                                  {{ lbl['user-level-number-point'] }}
                                </div>
                                <div v-else class="mt-3 int-point">
                                  {{ stringFormat(item_badge.Value) }}
                                  {{ lbl['user-level-number-point'] }}
                                  ++
                                </div>
                                <div
                                  class="mt-3"
                                  v-html="item_badge.Description"
                                ></div>
                              </template>
                            </a-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import Locale from '@/helper/locale.js'

export default {
  name: 'HistoryLevel',
  components: {
    Header,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      selectionChoice: [],
      historyList: [],
      options: [],
      value: [],
      dataUserLevel: {},
      isNoData: false,
    }
  },
  computed: {
    availableOptions() {
      return this.options.filter(opt => this.value.indexOf(opt) === -1)
    },
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_history',
      'member_level',
      'view_member_level_history',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.currenttitle = this.lbl['user-level-member-level']
      this.current = this.lbl['user-level-inactive-member-level']
      this.selectionChoice = [
        {
          id: '1',
          name: this.lbl['user-level-earning-point'],
          desc: this.lbl['user-level-point-hunter'],
          img: 'loupe.svg',
        },
        {
          id: '2',
          name: this.lbl['user-level-total-spending'],
          desc: this.lbl['user-level-most-spending-win'],
          img: 'brightness_low.svg',
        },
      ]
      if (Locale.getLocaleShort() == 'th') {
        moment.locale('th')
      } else {
        moment.locale('en')
      }
      this.getYearList()
      this.getDataInfo(null, null)
    },
    getYearList() {
      this.options = []
      for (let i = 0; i < 7; i++) {
        var year = new Date().getFullYear() + 3
        this.options.push((year - i).toString())
      }
    },
    getDataInfo(startyear, endyear) {
      this.isNoData = false
      return new Promise(resolve => {
        BzbsUserProfile.getHistory(startyear, endyear)
          .then(res => {
            if (res != null) {
              if (res.data.length > 0) {
                this.genDataInfo(res.data)
              } else {
                this.historyList = []
                this.isNoData = true
              }
            }
            resolve(res.data)
          })
          .catch(() => {
            resolve(null)
          })
      })
    },
    genDataInfo(data) {
      if (data != null) {
        this.historyList = data

        this.historyList.sort(function (a, b) {
          return a.Value[0].IsUndo === b.Value[0].IsUndo
            ? 0
            : a.Value[0].IsUndo
            ? -1
            : 1
        })

        _.forEach(this.historyList, item => {
          if (item.Value) {
            item.Value.sort(function (a, b) {
              if (a.Value < b.Value) {
                return -1
              }
              if (a.Value > b.Value) {
                return 1
              }
              return 0
            })
          }
        })
      } else {
        this.historyList = []
        this.isNoData = true
      }
    },
    dataMode(data) {
      if (data == 'spending') {
        return '2'
      } else {
        return '1'
      }
    },
    dateFormat(date) {
      if (date) {
        return moment.unix(date).format('DD/MM/yyyy')
      } else {
        return '-'
      }
    },
    stringFormat(value) {
      if (String(value).length > 3) {
        const result = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return result
      } else {
        return String(value)
      }
    },
    dateShotFormat(data) {
      if (data[0].UpdateDate != null) {
        var date_srt = moment.unix(data[0].UpdateDate).format('D MMMM ')
        var date_year = moment.unix(data[0].UpdateDate).format('yyyy')

        return date_srt + date_year
      } else {
        return ''
      }
    },
    isDisplayUserLevel(value) {
      return value
    },
    onOptionClick(tags) {
      if (tags.length == 2) {
        tags.sort(function (a, b) {
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }
          return 0
        })
        this.getDataInfo(tags[0], tags[1])
      } else if (tags.length == 1) {
        this.getDataInfo(tags[0], 0)
      } else {
        this.getDataInfo(null, null)
      }
    },
    handleRedo() {
      Account.bzbsAnalyticTracking(
        'member_level_history',
        'member_level',
        'click_member_level_history_exit',
        'on click',
      )
      localStorage.removeItem('dataUserLevel')
      this.dataUserLevel.isEdit = true
      this.dataUserLevel.isUpdate = false
      localStorage.setItem('dataUserLevel', JSON.stringify(this.dataUserLevel))
      this.$router.replace({
        name: 'PreviewLevel',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
</style>
